@import "../base/variables"
@import "../base/mixins"

.header
    position: absolute
    width: 100%
    left: 0
    top: 0
    z-index: 12
    background-color: $white
    transition: transform .8s ease

    .sidenav
        display: none

    &.fixed
        position: fixed
        transform: translate(0, -70px)
        top: -70px
        left: 0
        width: 100vw

        .navbar
            padding: 15px 45px

        .navbar-brand
            max-width: 60px

        //.btn-outline-primary
        //    display: none

    &.show
        top: 0
        transform: translate(0, 0)

    &__link
        margin-right: 26px
        +d(1280)
            margin-right: 10px
        +d(1024)
            margin-right: 30px

.logo
    max-width: 135px

    @include d(991)
        max-width: 120px

.header
    &.logged

        .navbar-user
            display: flex
            +d(1199)
                order: -1
                padding-bottom: 20px
                margin-bottom: 20px
                border-bottom: 1px solid $grey
                width: 100%
            +d(767)
                padding-bottom: 10px
                margin-bottom: 10px

        .sidenav
            display: none
            +d(1199)
                display: block
                padding: 10px 0
                border-top: 1px solid $grey
                margin-top: 10px
                width: 100%
                +d(767)
                    padding: 5px
                    margin-top: 5px

            li
                margin-bottom: 0

                a
                    +d(1199)
                        display: flex
                        flex-direction: row
                        align-items: flex-start
                        justify-content: flex-start
                        padding-left: 0
                        font-size: 16px
                        .svg-icon_md
                            margin-right: 20px
                            margin-bottom: 0

        .sidenav li:nth-last-child(2), .sidenav li:last-child
            display: block

    .navbar-collapse.show, .navbar-collapse.collapsing
        +d(1199)
            display: flex
            flex-direction: column
            align-items: flex-start
            justify-content: flex-start

    .navbar-nav
        +d(1199)
            margin: 0
            width: 100%

.navbar-user
    font-size: 16px
    display: flex
    align-items: center

    &__img
        width: 40px
        height: 40px
        border-radius: 50%
        margin-left: 20px

        img
            object-fit: cover
            object-position: center
