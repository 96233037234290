@import "../base/variables"
@import "../base/mixins"

.story
    &-hero
        padding: 20px 0 138px 0
        +d(1199)
            padding: 0 0 100px 0
        +d(767)
            padding: 0

        .title-1, h1
            font-size: 80px
            line-height: 1em
            margin-bottom: 60px
            +d(991)
                font-size: 60px
                margin-bottom: 30px
            +d(575)
                font-size: 50px
            +d(360)
                font-size: 47px

        &__content
            margin: 34px 0px 30px 50px
            +d(991)
                margin: 0 0 50px 0
            +d(767)
                margin: 0 0 30px 0

        &__video
            position: relative
            margin-bottom: 140px

            +d(991)
                margin-bottom: 50px
                margin-left: -30px
                margin-right: -30px
            +d(767)
                margin-bottom: 30px

            img, picture
                object-fit: cover
                min-height: 280px
                object-position: center

        &__description
            font-size: 18px
            text-align: center
            max-width: 830px
            margin: 0 auto 40px auto
            +d(991)
                text-align: left

    &__play
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

.story-text
    position: relative
    padding-top: 127px
    +d(1199)
        padding-top: 80px
    +d(991)
        padding-top: 40px

    &::before
        content: ""
        width: 100%
        height: 860px
        background-color: $black
        position: absolute
        top: 0
        left: 0
        +d(991)
            height: 100%

    &__content
        column-count: 2
        column-gap: 120px
        margin: 0 60px 83px 60px

        .show-on-mobile
            +d(991)
                display: inline-block

        +d(991)
            column-count: 1
            margin: 0 0 50px 0

        p
            color: $white

            &:not(:last-child)
                margin-bottom: 15px

            &:not(:nth-child(-n + 2))
                +d(991)
                    display: none

            span
                color: $primary

    &__img
        max-height: 495px
        +d(991)
            margin: 0 -30px

.timeline
    position: relative
    margin: 0 auto
    max-width: 1340px
    padding: 125px 0 180px
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 60px 0

    &__title
        text-align: center
        margin-bottom: 122px
        +d(1199)
            margin-bottom: 60px

    &__content
        min-height: 475px
        width: 1310px
        position: relative
        display: flex
        flex-wrap: wrap
        justify-content: center

    &__line
        position: absolute
        top: 46%
        left: 15px
        width: calc(100% - 60px)
        height: 4px
        background-color: $black

        &::after, &::before
            content: ""
            position: absolute
            width: 12px
            height: 12px
            border-radius: 50%
            top: 50%
            transform: translate(0, -50%)
            background-color: $black

        &::before
            left: 0

        &::after
            right: 0

    &-box
        text-align: center
        max-width: 187px
        flex: 0 0 187px
        padding: 0 5px
        position: relative

        &::before, &::after
            content: ""
            position: absolute
            background-color: $black

        &::before
            width: 4px
            height: 100px

        &::after
            width: 12px
            height: 12px
            border-radius: 50%

        &:nth-child(even)
            margin-bottom: 258px

            &::before
                top: 122%

            &::after
                top: 115%
                left: 50%
                transform: translate(-30%, 0)

        &:nth-child(odd)
            order: 1

            &::before
                bottom: 122%

            &::after
                bottom: 115%
                left: 50%
                transform: translate(-30%, 0)

        span
            font-weight: 900
            color: $primary
            font-size: 30px
            text-align: center
            display: block
            margin-bottom: 10px
            +d(1199)
                font-size: 24px
            +d(991)
                font-size: 20px
            +d(767)
                font-size: 18px

.team
    padding: 126px 0 150px 0
    background-color: $grey
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 40px 0

    &__subtitle
        max-width: 520px
        margin-top: 20px
        +d(991)
            max-width: 100%

    &__content
        padding-top: 69px
        max-width: 1120px

    &-box
        display: flex
        +d(991)
            flex-direction: column

        &:not(:last-child)
            margin-bottom: 140px
            +d(1199)
                margin-bottom: 100px
            +d(991)
                margin-bottom: 50px

        &__img
            max-width: 420px
            flex: 0 0 420px
            margin-right: 60px
            +d(991)
                max-width: 360px
                margin-right: 30px
            +d(991)
                margin: 0 auto
            +d(767)
                margin: 0 -30px
                max-width: calc(100% + 60px)

        &__header
            display: flex
            margin: 28px 0 50px 0
            flex-wrap: wrap
            +d(991)
                margin: 10px 0 20px 0

            h4, .title-4
                font-size: 30px

        &__name
            max-width: 50%
            flex: 0 0 50%
            color: $primary

        &__article
            p
                &:not(:last-child)
                    margin-bottom: 9px

                &:not(:first-child)
                    +d(991)
                        display: none

            a
                color: $primary
                margin-top: 20px

.support
    padding: 110px 0 340px 0
    +d(1199)
        padding: 100px 0 200px 0
    +d(991)
        padding: 60px 0
    +d(767)
        padding: 40px 0

    &__title
        text-align: center
        margin-bottom: 80px
        +d(1199)
            margin-bottom: 50px
        +d(991)
            margin-bottom: 40px
            text-align: left
        +d(767)
            margin-bottom: 30px

        span
            display: block

    &__content
        column-count: 2
        column-gap: 120px
        max-width: 1080px
        margin: 0 auto 60px auto
        +d(767)
            column-count: 1

        span
            color: $primary

        p
            &:not(:last-child)
                margin-bottom: 16px

    &__wrapper
        max-width: 1320px
        margin: 0 auto
        position: relative

        &:before
            content: ""
            width: 200vw
            background-color: $black
            position: absolute
            left: -50vw
            top: 120px
            bottom: 120px
            z-index: -1
            +d(991)
                bottom: 220px

    &-slider
        display: flex
        justify-content: space-between
        z-index: 1
        flex-wrap: wrap
        +d(767)
            margin-bottom: 30px

        .slick-dots
            margin: 0 auto

    &-box
        max-width: 410px
        margin: 0 auto
        flex: 0 0 30%

        &__img
            margin-bottom: 35px

        &__header
            span
                color: $primary
                font-size: 18px
                font-weight: 600
                display: block
                margin-bottom: 5px

    &__subtitle
        margin: 160px 0 160px 60px
        color: $white
        +d(1199)
            margin: 100px 0
        +d(991)
            margin: 0 0 50px 0
        +d(575)
            margin: 0 0 30px 0

    &__text
        margin: 170px 0 114px -30px
        color: $white
        +d(991)
            margin: 80px 0 114px 0

        p
            margin-bottom: 15px

        span
            color: $primary
