@import "../base/variables"
@import "../base/mixins"

.seo
    &-hero
        padding: 20px 0 35px 0
        +d(991)
            padding: 0 0 30px 0

        &__title
            max-width: 640px
            +d(991)
                max-width: 100%

        &__content
            max-width: 430px
            font-size: 18px
            +d(991)
                max-width: 100%

.ucat
    padding-top: 70px
    +d(991)
        padding-top: 50px

    &::before
        content: ""
        width: 100%
        height: 980px
        background-color: $grey
        position: absolute
        top: 0
        left: 0

    &__img
        margin: 80px 0
        +d(991)
            margin: 50px -30px

.text
    &__content
        max-width: 540px
        margin: 50px 0
        +d(991)
            max-width: 100%
            margin: 10px 0

        p
            margin-bottom: 15px

        h6, .title-6
            margin: 40px 0 15px
            +d(991)
                margin: 20px 0 10px

        ul
            margin-bottom: 35px

            li
                position: relative
                padding-left: 20px
                margin-bottom: 5px

                &::before
                    content: ""
                    width: 6px
                    height: 6px
                    background-color: $primary
                    border-radius: 50%
                    position: absolute
                    left: 0
                    top: 10px

        span
            color: $primary

        h4, .title-4
            font-size: 30px

        .btn
            margin-top: 20px

.require
    padding: 130px 0
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 50px 0

    &__title
        text-align: center
        margin-bottom: 100px
        +d(991)
            margin-bottom: 60px

        span
            color: $primary

    &__content
        background-color: $black
        color: $white
        padding: 160px 0 90px
        +d(1199)
            padding: 100px 0
        +d(991)
            padding-right: 50px 0

        .text__content
            max-width: 100%

        ul
            column-count: 2
            margin-right: 40px
            +d(991)
                column-count: 1
                margin: 40px 0

.details
    padding-bottom: 150px
    +d(1199)
        padding-bottom: 100px
    +d(991)
        padding-bottom: 50px

.cost
    padding-bottom: 100px
    +d(991)
        padding-bottom: 0

    &__img
        +d(991)
            margin: 40px auto
            max-width: 500px
        +d(767)
            max-width: calc(100% + 30px)
            margin: 0 -30px

    &__title
        text-align: center
        margin-bottom: 100px

        span
            color: $primary

.ucat-table
    padding: 140px 0 100px 0
    background-color: $grey
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 50px 0

    &__title
        text-align: center
        margin-bottom: 40px

.table
    p
        text-align: center

    table
        width: 100%
        border-collapse: collapse
        border-spacing: 0
        background-color: $white
        margin-bottom: 50px

        tr
            &:first-child
                th
                    color: $white
                    background-color: $black
                    font-weight: 400
                    padding: 26px 47px
                    width: 50%
                    border: 1px solid $greyLight

        tr
            td
                padding: 26px 47px
                vertical-align: middle
                border: 1px solid $greyLight

                &:first-child
                    font-weight: 600

.hard
    padding-top: 160px
    +d(1199)
        padding-top: 100px
    +d(991)
        padding-top: 80px
    +d(767)
        padding-top: 40px

    &__img
        position: relative
        left: -60px
        max-width: 540px
        margin: 0 auto
        +d(991)
            left: 0
            margin: 40px auto
        +d(767)
            max-width: calc(100% + 60px)
            margin: 40px -30px

.steps
    padding: 100px 0
    +d(1199)
        padding: 60px 0

.long
    color: $white
    padding: 140px 0 200px 0
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 50px 0

    &::before
        content: ""
        width: 100%
        height: 800px
        background-color: $black
        position: absolute
        top: 0
        left: 0

    &__play
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

    &__video
        height: 430px
        position: relative
        left: -60px
        top: 40px

        img, picture
            object-fit: cover

        +d(991)
            left: 0
            top: 0
            margin: 0 -30px
        +d(575)
            height: 280px

//.score__title
//    max-width: 50%
//    +d(1199)
//        max-width: 100%

.good-score
    padding: 50px 0
    background-color: $grey

    &__img
        max-width: 540px
        margin-right: 110px
        +d(991)
            margin: 40px auto
        +d(767)
            max-width: calc(100% + 30px)
            margin: 40px -30px

.interpret
    padding: 160px 0
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 50px 0
