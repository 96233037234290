@import "../base/variables"
@import "../base/mixins"

.teachers
    &-hero
        padding: 17px 0 50px 0
        +d(1199)
            padding: 0 0 20px 0

        &__content
            margin: 20px 0 0 50px
            +d(991)
                margin: 0 0 30px 0

    &__cta
        background-color: $white

.help
    padding: 100px 0 65px 0
    background-color: $grey
    +d(1199)
        padding: 60px 0

    &__title
        text-align: center
        color: $primary
        margin-bottom: 28px
        +d(991)
            margin-bottom: 15px

    &__items
        padding: 0 80px

    &-box
        padding: 50px 40px 60px 40px
        background-color: transparent
        box-shadow: 0 0 0 $white
        transition: background-color .4s ease, box-shadow .4s ease
        +d(991)
            padding: 30px 20px

        .title-5, h5
            margin-bottom: 15px

        &:hover
            color: inherit
            text-decoration: none
            background-color: #f7f7f7
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1)

            .title-5, h5
                color: $primary

.doctor
    padding: 110px 0
    +d(1199)
        padding: 40px 0

    &__content
        margin-top: 50px
        max-width: 540px
        +d(991)
            max-width: 100%

        .title-2, h2
            margin-bottom: 40px

        +d(991)
            max-width: 100%

        p
            margin-bottom: 15px
            max-width: 540px
            +d(991)
                max-width: 100%

        ul
            column-count: 2
            margin-bottom: 30px

    &__img
        max-width: 600px
        +d(991)
            margin: 0 auto
        +d(767)
            max-width: calc(100% + 30px)
            margin: 0 -30px

.understanding
    padding: 33px 0 135px 0
    +d(1199)
        padding: 30px 0 80px
    +d(991)
        padding: 30px 0 50px 0

    &__content
        max-width: 540px
        +d(991)
            max-width: 100%

.portal
    padding: 155px 0
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 60px 0
    +d(575)
        padding: 40px 0
    color: $white

    &::before
        content: ""
        width: 100%
        height: 960px
        background-color: $black
        position: absolute
        top: 0
        left: 0

    &__title
        margin-bottom: 90px
        text-align: center
        +d(1199)
            margin-bottom: 20px

    &__content
        max-width: 540px
        margin-bottom: 90px
        +d(991)
            max-width: 100%

        .title-5, h5
            color: $primary

    &__btn
        text-align: center
        margin-bottom: 70px

    &__video
        padding-bottom: 52%
        position: relative
        background-size: cover
        background-repeat: no-repeat
        background-position: center
        min-height: 400px
        +d(767)
        margin: 0 -30px

    &__play
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

.download
    padding: 8px 0 50px 0

    &__title
        margin-bottom: 90px
        text-align: center
        +d(991)
            margin-bottom: 60px

        p
            max-width: 540px
            margin: 0 auto

    &-box
        max-width: 370px
        margin-bottom: 109px
        display: flex
        +d(991)
            flex-direction: column
            margin: 0 auto
            max-width: 500px
            margin-bottom: 60px

        &__icon
            margin-right: 40px
            max-height: 55px
            position: relative
            +d(991)
                margin-right: 0
                margin-bottom: 30px

            &::before
                content: ""
                background-image: url(../img/svg/ellipse.svg)
                background-size: contain
                background-repeat: no-repeat
                background-position: top left
                position: absolute
                top: 0
                left: 0
                height: 100%
                width: 100%
                z-index: -1
                opacity: .3

        .title-6, h6
            margin-bottom: 15px

.presenatation-link
    text-align: center
    max-width: 220px
    font-family: $titleFont
    font-size: 18px
    line-height: 27px
    display: flex
    flex-direction: column

    .svg-icon
        width: 50px
        height: 50px
        margin: 0 auto 15px auto

.events
    background-color: $grey
    padding-bottom: 20px

    &__img
        margin-left: -30px
        max-width: 730px
        +d(991)
            margin: 0 -30px
            max-width: calc(100% + 30px)

    &__content
        max-width: 540px
        margin-left: 40px
        margin-top: 100px
        +d(991)
            max-width: 100%
            margin-left: 0
            margin-top: 40px

        .link
            margin-bottom: 15px

    &__row
        display: flex
        align-items: center
        +d(767)
            flex-direction: column

        .btn
            margin-right: 40px
            +d(991)
                order: 1
                margin: 40px auto 0 auto


.personal-links
    padding: 20px 0
    +d(991)
        padding-left: 30px

    a
        text-decoration: underline

        + a
            margin-left: 30px

.schedule-sec
    padding: 70px 0
    +m(992)
        padding: 140px 0

    .title-2
        + p
            max-width: 688px
            margin: 0 auto
            text-align: center

.schedule-days
    padding-top: 40px
    padding-bottom: 120px
    +m(992)
        padding-top: 80px

.schedule-day
    margin-bottom: 24px

    &__title
        border-radius: 10px 10px 0 0
        background: $black
        padding: 20px 30px
        display: flex
        align-items: center
        text-decoration: none

        &:hover
            text-decoration: none

        +d(991)
            position: relative
            &:before
                position: absolute
                right: 20px
                top: 50%
                content: ''
                width: 12px
                height: 6px
                background: url("../img/svg/ic_arrow_down.svg") no-repeat center
                background-size: contain
                transform: translateY(-50%) rotate(180deg)
            &.collapsed
                &:before
                    transform: translateY(-50%)

            &.collapsed
                border-radius: 10px

        +m(992)
            pointer-events: none

        span
            min-width: 72px
            font-weight: bold
            font-size: 24px
            line-height: 1.45
            color: $white

        p
            font-weight: 500
            font-size: 12px
            line-height: 1.5
            color: $greyDark
            margin-left: 20px

    &__content
        border-left: 1px solid #F2F2F2
        border-right: 1px solid #F2F2F2
        border-bottom: 1px solid #F2F2F2
        border-radius: 0 0 10px 10px
        padding: 30px
        box-shadow: 0 7px 19px rgba(0, 0, 0, 0.03)
        +m(992)
            display: block !important
        +d(991)
    //display: none
    &__row
        display: flex

        &:not(:last-child)
            padding-bottom: 20px
            margin-bottom: 20px
            border-bottom: 1px solid #A0A0A0

    &__time
        font-weight: 500
        font-size: 12px
        line-height: 1.5
        color: $greyDark
        min-width: 70px
        padding-right: 10px
        border-right: 1px solid #E0E0E0

        span
            display: block

            &:first-child
                color: $primary

    &__text
        padding-left: 20px
        font-size: 14px
        line-height: 1.71

.info-section
    padding: 70px 0
    +d(991)
        .title-2, h2
            font-size: 24px
            margin-bottom: 0

    +m(992)
        padding: 90px 0

.info-block
    margin: 0 auto
    max-width: 955px

    &__title
        border-radius: 10px 10px 0 0
        background: $black
        padding: 20px 30px
        display: flex
        align-items: center
        text-decoration: none
        font-size: 24px
        line-height: 1.45
        color: $white

    &__content
        border-left: 1px solid #F2F2F2
        border-right: 1px solid #F2F2F2
        border-bottom: 1px solid #F2F2F2
        border-radius: 0 0 10px 10px
        padding: 30px
        box-shadow: 0 7px 19px rgba(0, 0, 0, 0.03)
        background: $white

    &__row
        display: flex

        &:not(:last-child)
            border-bottom: 1px solid #E0E0E0

    &__left-col
        font-weight: 500
        font-size: 16px
        line-height: 1.5
        color: $primary
        border-right: 1px solid #E0E0E0
        padding: 50px 20px 50px 0
        flex-basis: 25%
        min-width: 25%
        +d(991)
            flex-basis: 35%
            min-width: 35%

    &__right-col
        padding: 50px 20px 50px 20px
        font-size: 18px
        line-height: 1.5
        flex-basis: 75%

.text-block-icon
    margin-bottom: 50px

    &__title
        font-family: $titleFont
        font-size: 20px
        line-height: 1.4
        font-weight: 900
        margin-bottom: 24px
        +m(992)
            font-size: 30px

        span
            color: $primary

    .svg-icon
        display: inline-block
        margin-right: 20px
        width: 37px
        height: 37px
        position: relative
        top: -5px

    p
        margin-bottom: 20px
