@font-face {
    font-family: 'Orpheus Pro';
    src: url('../fonts/OrpheusPro/Orpheus-Pro.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: // url('../fonts/montserrat/Montserrat-Medium.woff') format('woff'),
        url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: // url('../fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
        url('../fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: // url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
        url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/playFairDisplay/PlayfairDisplay-Black.woff2') format('woff2');
    // url('../fonts/playFairDisplay/PlayfairDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
