//mixins

//width
=d($width)
    @media only screen and (max-width: $width+ "px")
        @content

=m($width)
    @media only screen and (min-width: $width + "px")
        @content


//height
@mixin dh($height)
    @media only screen and (max-height: $height + "px")
        @content


@mixin mh($height)
    @media only screen and (min-height: $height + "px")
        @content


//iPhone landscape
@mixin iphone
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape)
        @content


//iPhoneX landscape
@mixin iphoneX
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)
        @content


//iPad Pro portrait
@mixin ipadproport
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait)
        @content


//middle
@mixin md
    @include r(1023)
        @content


//small
@mixin sm
    @include r(767)
        @content


//xsmall
@mixin xs
    @include r(599)
        @content


//clearfix
@mixin clear
    &:after
        content: " "
        display: table
        clear: both


//Images
@mixin img
    display: block
    width: 100%
    height: auto


//performance
@mixin gpu
    transform: translate3d(0, 0, 0)


//scroll fix
@mixin smooth-scroll
    -webkit-overflow-scrolling: touch


