@import "../base/variables"
@import "../base/mixins"

.doctor
    &-page
        overflow: visible

    &-hero
        padding-top: 32px
        +d(1199)
            padding-top: 0
        +d(991)
            margin-bottom: 30px

    &__title
        margin-bottom: 30px

    &-tab
        max-width: calc(50% - 15px)
        margin-bottom: 20px

        .nav-item
            overflow: hidden

            &:first-child
                .nav-link
                    border-top-left-radius: 10px
                    border-bottom-left-radius: 10px

            &:last-child
                .nav-link
                    border-top-right-radius: 10px
                    border-bottom-right-radius: 10px

        +d(991)
            max-width: 100%
            margin-bottom: 30px

        .nav-item
            width: 50%
            margin-right: 0

            .nav-link
                text-align: center
                border-radius: 0
                border: 1px solid $primary
                +d(575)
                    font-size: 14px
                    font-weight: 400

    &-tab__video
        position: relative
        // min-height: 350px
        +d(991)
            margin: 0 -30px

    &-tab__content
        margin: 4px 0 0 46px
        +d(991)
            margin: 0 0 40px 0

        p
            margin-bottom: 20px

    &__play
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

    &-tabs
        padding: 80px 0
        background-color: $grey
        +d(991)
            padding: 40px 0 0 0
// .doctor-steps


.doctor-steps
    padding: 93px 0
    +d(991)
        padding: 40px 0
    +d(575)
        padding: 0

    &__title
        text-align: center
        +d(991)
            margin-bottom: 30px

    .list-group__wrapper
        overflow: auto
        position: sticky
        top: 100px
        left: 0
        +d(991)
            display: none

    .list-group
        position: sticky
        top: 100px
        left: 0
        padding-top: 23px
        +d(991)
            position: relative
            padding-top: 0
            top: 0
            display: flex
            flex-wrap: nowrap
            flex-direction: row
            min-width: 900px
            overflow: auto

        &-item
            border: none
            padding: 0 0 35px 30px
            position: relative
            max-width: 330px
            +d(991)
                padding: 20px

            &:first-child
                &::after
                    top: 8px
                    height: calc(100% - 8px)
                    +d(991)
                        height: 1px
                        width: 100%
                        top: 6px

            &:last-child
                &::after
                    display: none

            &.active
                color: $black
                background-color: inherit
                +d(991)
                    color: $primary

                span
                    color: $primary

                .step-item__content
                    display: block

            &::before
                content: ""
                width: 13px
                height: 13px
                background-color: $primary
                border-radius: 50%
                position: absolute
                top: 6px
                left: 0
                z-index: 10
                +d(991)
                    top: 0
                    left: 0

            &:last-child, &:first-child
                border-radius: 0

            &::after
                content: ""
                width: 1px
                height: 100%
                background-color: $black
                position: absolute
                top: -3px
                left: 6px
                height: calc(100% + 8px)
                z-index: 4
                +d(991)
                    height: 1px
                    width: 100%
                    top: 7px

            span
                text-transform: uppercase
                color: $black
                font-size: 20px
                font-weight: 700
                display: block
                margin-bottom: 5px

            strong
                font-size: 20px
                line-height: 1.5em
                margin-bottom: 10px

            p
                font-size: 12px
                line-height: 1.667em

.step-item__content
    display: none

.step
    &__wrapper
        margin-left: 30px

        max-width: 540px
        padding-top: 40px

        .slick-dots
            position: absolute
            top: 0
            left: 50%
            transform: translate(-50%, -50%)

        +d(991)
            margin-left: 0
            max-width: 100%
            margin-bottom: 40px

    &__content
        margin-bottom: 120px
        +d(991)
            margin-bottom: 40px

        .title-3, h3
            margin-bottom: 20px

            span
                display: block

        p
            margin-bottom: 15px

        a
            color: $primary

        .title-6, h6
            margin-bottom: 15px
            font-size: 18px

        .title-5, h5
            margin-bottom: 15px
