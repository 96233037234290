@import "../base/variables"
@import "../base/mixins"

.giving
    &-hero
        padding: 10px 0 50px 0
        +d(1199)
            padding: 0 0 40px 0

.commit
    padding: 114px 0 80px 0
    background-color: $grey
    +d(1199)
        padding: 80px 0 60px 0
    +d(991)
        padding: 60px 0
    +d(575)
        padding: 50px 0

    &__content
        max-width: 480px
        margin-top: 40px
        +d(991)
            max-width: 100%
            margin-bottom: 40px
            margin-top: 0

    &__items
        margin-left: 40px
        +d(1199)
            margin-left: 0

        .title-5, h5
            margin-bottom: 30px

        .potential-box:not(:last-child)
            margin-bottom: 50px

.partnership
    padding: 180px 0 120px 0
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 60px 0 0 0

    &-slider
        max-width: 480px

    &__content
        max-width: 480px
        margin: 18px 0 0 43px
        +d(1199)
            margin: 20px 0 0 0
        +d(991)
            max-width: 100%

        p
            &:not(:last-child)
                margin-bottom: 15px

.scholarship
    margin-top: 80px
    padding: 125px 0 130px 0
    background-color: $black
    color: $white
    +d(1199)
        margin-top: 50px
        padding: 80px 0
    +d(991)
        padding: 50px 0 0 0

    &__img
        max-width: 490px
        margin-top: -195px
        +d(1199)
            margin-top: -160px
        +d(991)
            margin: 0 auto

        +d(767)
            max-width: calc(100% + 60px)
            margin: 0 -30px

    &__content
        span
            color: $primary

.charity
    padding: 210px 0
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 60px 0

    &__content
        max-width: 500px
        margin-bottom: 60px
        +d(991)
            max-width: 100%

    &-slider__nav
        +d(991)
            display: none

        .slider__btn
            width: 55px
            height: 55px
            background-color: $white

            &:not(:last-child)
                margin-right: 40px

            .arrow-icon
                width: 100%
                height: 100%

    &-slider
        height: 100%
        margin-bottom: 40px

        .slick-slide div
            +m(992)
                display: flex
                flex-direction: column
                margin-bottom: 50px
                margin-right: 40px

            img
                +m(992)
                    margin-bottom: 15px

            p
                +m(992)
                    width: 100% !important

        &__item
            display: flex
            align-items: flex-start
            justify-content: space-between
            flex-wrap: wrap

        &__box
            display: inline-block
            max-width: 240px
            margin-bottom: 50px
            margin-right: 40px

            img
                width: auto
                max-width: 100%
                margin-bottom: 30px

                p
                    font-weight: 600

.alumni
    padding-bottom: 190px
    +d(1199)
        padding-bottom: 100px
    +d(991)
        padding-bottom: 50px

    &__img
        max-width: 490px
        +d(991)
            margin: 0 auto 50px auto
        +d(767)
            max-width: calc(100% + 60px)
            margin: 0 -30px 50px -30px
