/* Слайдер */
.slick-slider
    min-width: 0

/* Ограничивающая оболочка */
.slick-list
    overflow: hidden
    width: 100%

/* Лента слайдов */
.slick-track
    display: flex
    align-items: flex-start

// .testimonials-slider .slick-track
//   display: block

// .testimonials-slider .slick-slide
//   height: 200px
// /* Слайд */
// .slick-slide

// /* Слайд активный */
// .slick-slide.slick-active

// /* Слайд основной */
// .slick-slide.slick-current

// /* Слайд по центру */
// .slick-slide.slick-center

// /* Стрелка */
// .slick-arrow

// /* Стрелка влево */
// .slick-arrow.slick-prev

// /* Стрелка вправо */
// .slick-arrow.slick-next

// /* Стрелка не активная */
// .slick-arrow.slick-disabled

// /* Точки */
.slick-dots
    display: flex
    align-items: center
    justify-content: center

.slick-dots li
    flex: 0 0 8px

    &.slick-active
        button
            background-color: $primary

    &:not(:last-child)
        margin-right: 15px


.slick-dots button
    border: none
    font-size: 0
    width: 8px
    height: 8px
    background-color: $greyDark
    border-radius: 50%
    padding: 0
