@import "../base/variables"
@import "../base/mixins"

.resources
    &-hero
        padding: 14px 0 130px 0
        +d(1199)
            padding: 0 0 100px 0
        +d(991)
            padding: 0 0 50px 0

        &__title
            margin-bottom: 85px
            +d(1199)
                margin-bottom: 60px
            +d(991)
                margin-bottom: 20px

        &__content
            max-width: 480px
            margin: 28px 0 60px 0
            +d(1199)
                margin: 0 0 40px 0
            +d(991)
                max-width: 100%

            p
                margin-bottom: 20px

        &__img

            +d(991)
                margin-bottom: 40px

    &-box
        display: flex
        margin-bottom: 35px
        +d(991)
            flex-direction: column

        &__img
            margin-right: 80px
            +d(991)
                margin-right: 0
                margin-bottom: 30px

        &__content
            .title-5, h5
                margin-bottom: 8px

.what
    padding: 120px 0
    background-color: $grey
    +d(1199)
        padding: 80px 0
    +d(991)
        padding: 60px 0 20px 0

.handbook
    padding: 140px 0 233px
    +d(991)
        padding: 0 0 60px 0

    &__img
        max-width: 540px
        +d(991)
            margin: 0 auto 40px auto

        +d(767)
            max-width: calc(100% + 60px)
            margin: 0 -30px 40px -30px

    &__content
        margin-left: 40px
        +d(991)
            margin-left: 0

.bootcamp
    color: $white
    background-color: $black
    padding: 150px 0
    margin-bottom: 45px
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 60px 0 0 0

    &__content
        max-width: 480px

    &__video
        position: absolute
        width: 720px
        top: -45px
        bottom: -45px
        right: 0
        background-size: cover
        background-repeat: no-repeat
        background-position: center
        +d(1199)
            width: 540px
        +d(1024)
            width: 420px
        +d(991)
            width: calc(100% + 30px)
            margin: 0 -30px
            position: relative
            z-index: 1
            min-height: 280px
            height: 50vw
            bottom: auto
            top: 0

    &__play
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

        .play-btn
            margin-right: 0

.double
    padding: 130px 0 120px 0

    +d(1199)
        padding: 100px 0

    +d(991)
        padding: 40px 0

.exam
    padding: 180px 0 10px 0
    background-color: $grey
    +d(1199)
        padding: 100px 0 0 0
    +d(991)
        padding: 0

    &__title
        margin-bottom: 40px

    &-slider
        +d(991)
            margin-bottom: 30px

        &__item
            max-height: 570px
            +d(991)
                max-height: 360px

            img, picture
                object-fit: cover
                object-position: center
                height: 100%

        &__nav
            margin: 0 0 50px 50px
            +d(991)
                text-align: center
                margin-bottom: 40px

            .slider__btn:not(:last-child)
                margin-right: 20px

    &__content
        margin: 12px 0 50px 50px
        +d(991)
            margin: 0 0 40px 0
