// footer styles
@import "../base/variables"
@import "../base/mixins"

.footer
    background-color: $blackLight
    position: relative
    padding-top: 82px
    color: $greyDark
    font-size: 14px
    line-height: 22px

    +d(1280)
        padding-top: 40px
    //+d(1199)
        //padding-top: 20px
    +d(991)
        border-top-left-radius: 10px
        border-top-right-radius: 10px
        overflow: hidden
        margin-top: -20px

    h6, .title-6
        text-transform: uppercase
        font-size: 14px
        line-height: 20px
        margin-bottom: 20px

    &__logo
        position: absolute
        top: 0
        left: 60px
        width: 100%
        max-width: 120px
        +d(1199)
            position: static
            margin-left: 30px

    &__list
        margin-bottom: 83px
        +d(1280)
            margin-bottom: 40px

    &-contacts
        display: flex
        align-items: flex-start
        margin-bottom: 27px
        +d(575)
        // margin: 0 -30px 20px

        .svg-icon_sm
            width: 12px
            margin-right: 10px
            margin-top: 5px

    &-row
        position: relative
        display: flex
        flex-wrap: wrap
        margin-left: 224px
        max-width: 960px

    &_top
        position: relative

    &_bottom
        background-color: $black
        display: flex
        align-items: center
        justify-content: space-between
        padding: 24px 60px
        +d(991)
            flex-direction: column-reverse
            text-align: center
        +d(767)
            padding: 20px 30px

.social-list
    display: flex
    align-items: center
    justify-content: center
    +d(991)
        margin: 20px 0

    li
        width: 20px

        &:not(:last-child)
            margin-right: 30px

.map
    width: 480px
    height: 560px
    position: absolute !important
    bottom: 100px
    right: 0
    +d(1440)
        width: 400px
    +d(1366)
        width: 360px
    +d(1280)
        width: 320px
        height: 440px
    +d(1199)
        position: relative !important
        top: 0
        left: 0
        width: 100%
        height: 400px
        overflow: hidden
        margin-bottom: 50px

    img
        object-fit: cover
        object-position: center
