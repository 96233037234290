@import "../base/variables"
@import "../base/mixins"

.reviews
    &-page
    .testimonials
        background-color: $grey
        padding: 150px 0 30px 0
        +d(1199)
            padding: 100px 0 30px 0
        +d(991)
            padding: 60px 0 30px 0
        +d(767)
            padding: 50px 0 30px 0

        .testimonials__social
            +d(991)
                flex-wrap: wrap

    &-hero
        padding-bottom: 40px

        .title-1
            font-size: 80px
            +d(991)
                font-size: 60px
            +d(575)
                font-size: 50px
            +d(360)
                font-size: 47px

        &__content
            margin-top: 40px
            +d(991)
                margin-top: 0

            p
                margin-bottom: 15px

    &__slider
        max-width: 600px
        margin: -10px 0 0 30px

    &__item
        padding: 47px 20px 45px 100px
        +d(991)
            padding: 40px
        +d(767)
            padding: 30px

    &-item__header
        display: flex
        flex-wrap: wrap

    &-item__row
        flex: 0 0 100%

    &-rating
        margin-bottom: 40px

        &__box
            background-color: $white
            border-radius: 10px
            border: 1px solid $greyLight
            padding: 26px 30px
            max-width: 304px
            text-align: center
            margin-bottom: 20px
            +d(991)
                margin: 0 auto 20px auto

        &__header
            display: flex
            align-items: center
            justify-content: center
            font-family: $titleFont
            font-size: 24px
            padding-bottom: 15px
            margin-bottom: 15px
            border-bottom: 1px solid $greyLight

            .svg-icon
                width: 22px
                height: 22px
                margin-right: 15px

        &__body, .reviews-rating__rating, .reviews-rating__reviews
            display: flex
            align-items: center
            justify-content: space-between

            .svg-icon
                width: 20px
                height: 20px
                margin: 0 10px

.rating
    display: flex
    align-items: center
    margin-left: 20px

    li
        .svg-icon_sm
            margin-right: 0
            width: 16px
            height: 16px

        &:not(:last-child)
            margin-right: 5px

.testimonials-video
    padding: 130px 0 140px
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 60px 0

    &__content
        margin: 70px 0 0 32px
        max-width: 540px
        +d(991)
            max-width: 100%
            margin: 0 0 30px 0

.video-slider
    display: flex
    margin: 64px auto 106px auto
    max-width: 1540px

    &__nav
        margin-top: 40px
        +d(991)
            margin-top: 0

        .slider__btn:first-child
            margin-right: 20px

        .arrow-icon
            fill: $white

    &__item
        height: 230px
        background-size: cover
        background-repeat: no-repeat
        background-position: center
        margin: 0 8px
        border-radius: 10px
        position: relative

    &__btn
        text-align: center

.best
    background-color: $black
    color: $white
    margin-top: 140px
    padding: 0 100px 100px 55px
    +d(991)
        padding: 50px 0 0 0

    &__img
        margin-top: -75px
        border-radius: 10px
        max-width: 450px
        overflow: hidden
        +d(991)
            margin: 0 auto

        +d(767)
            max-width: calc(100% + 30px)
            border-radius: 0
            margin: 0 -30px

    &__content
        display: flex
        align-items: flex-start
        margin: 140px 0 0 0
        +d(1199)
            margin-top: 60px
        +d(991)
            flex-wrap: wrap
            margin-top: 0
            margin-bottom: 50px

    &__title
        max-width: 260px
        margin-right: 60px
        +d(1199)
            margin-right: 30px
        +d(991)
            margin-right: 0
            margin-bottom: 20px
            max-width: 100%

    &__text
        max-width: 420px
        +d(991)
            max-width: 100%

        span
            color: $primary

.reviews-cta
    .title-2, h2
        font-size: 58px
        +d(1024)
            font-size: 50px
        +d(575)
            font-size: 44px
        +d(360)
            font-size: 40px
