@import "base/mixins"
@import "base/variables"

// libs using in project, uncomment what you need
// @import "../../node_modules/normalize.css/normalize.css";
// @import "../../node_modules/bootstrap-4-grid/css/grid.min.css";

// @import "../../node_modules/odometer/sass/odometer-theme-default"
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css"

@import "odo-overide.css"
@import "~odometer/themes/odometer-theme-default.css"

// @import "../libs/bootstrap4/dist/css/bootstrap.min.css"
@import "~bootstrap/scss/bootstrap.scss"

// base styles for project
@import "base/fonts.scss"
@import "base/base"
@import "base/animations"

// project components
@import "components/header"
@import "components/slick"
@import "components/footer"
@import "components/modal"

// project pages
@import "pages/index"
@import "pages/why-medentry"
@import "pages/story"
@import "pages/become-a-doctor"
@import "pages/compare"
@import "pages/free-resources"
@import "pages/giving-back"
@import "pages/reviews"
@import "pages/teachers"
@import "pages/seo-text"
@import "pages/contact"
@import "pages/register"
@import "pages/faq"
@import "pages/blog"
@import "pages/ucat"
@import "pages/interview"
@import "pages/shopping-cart"
@import "pages/dashboard"
@import "pages/widening"
@import "pages/need-know"
