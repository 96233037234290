@import "../base/variables"
@import "../base/mixins"

.hero
    padding-top: 66px
    +d(991)
        padding-top: 0

    &__title
        padding-left: 45px
        +d(1024)
            padding-left: 0

    &__img
        margin-top: 19px
        position: relative
        pointer-events: none

        img
            object-fit: cover
            +d(767)
                min-height: 200px
                object-position: right center

    &__play
        position: absolute
        top: 150%
        right: -20vw
        +m(1921)
            right: -30vw
        +d(1440)
            right: -17vw
        +d(1280)
            right: -15vw
        +d(1199)
            position: static

.statistic

    &__container
        background-color: $grey
        padding: 70px 90px 90px 90px
        border-radius: 10px
        margin-top: -70px
        display: flex
        justify-content: space-between
        +d(1280)
            padding: 50px 70px
        +d(991)
            padding: 40px 60px
        +d(767)
            flex-direction: column
            align-items: center
            margin-top: -40px

    &__box
        flex: 0 0 33.33%
        +d(767)
            margin-bottom: 30px
            text-align: center
            +d(991)
                flex: 0 0 100%

        span, i
            font-family: $titleFont
            font-size: 64px
            line-height: 1.250em
            color: $primary
            display: block
            font-style: normal
            +d(1280)
                font-size: 60px
            +d(1024)
                font-size: 50px
            +d(575)
                font-size: 44px

        i
            margin-left: 20px

        p
            font-size: 18px
            font-weight: 500

    &__number
        display: flex
        margin-bottom: 10px
        +d(767)
            margin-bottom: 0

.benefits
    padding: 160px 0 59px 0
    +d(991)
        padding: 100px 0 50px
    +d(575)
        padding: 50px 0

    &__title
        max-width: 500px
        +d(991)
            max-width: 100%

    &__content
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        margin: 19px 25px 0 -17px
        +d(991)
            margin: 0

    &-box
        flex: 0 0 50%
        max-width: 240px
        margin-bottom: 120px
        +d(991)
            max-width: 50%
            padding-right: 30px

        +d(767)
            flex: 0 0 100%
            max-width: 100%
            margin-bottom: 40px
            padding-right: 0

        &__header
            position: relative
            margin-bottom: 21px

            .svg-icon
                fill: $black

            &::before
                content: ""
                background-image: url(../img/svg/ellipse.svg)
                background-size: contain
                background-repeat: no-repeat
                background-position: top left
                position: absolute
                top: 0
                left: 0
                height: 100%
                width: 100%
                z-index: -1
                opacity: .3

            img, picture
                height: 44px
                width: auto

        .title-5, & h5
            color: $primary
            margin-bottom: 6px

.devices
    padding-bottom: 190px

    &::before
        content: ""
        position: absolute
        bottom: 0
        left: 0
        background-color: $black
        height: 38vw
        width: 100%

    img
        width: 92vw
        margin: 0 auto
        position: relative

    &__btn
        position: absolute
        bottom: 80px
        left: 50%
        transform: translate(-50%, 0)

.training
    padding-top: 145px
    +d(575)
        padding-top: 50px

    &__title
        max-width: 720px
        margin: 0 auto 80px auto
        text-align: center

        p
            max-width: 500px
            margin: 0 auto

    &__wrapper
        position: relative

    &__content
        display: flex
        justify-content: space-around
        flex-wrap: wrap

    &__img
        max-width: 950px
        width: 100%
        position: absolute
        top: 0
        left: 50%
        transform: translate(-50%, 0)
        z-index: -1
        +d(991)
            position: static
            transform: translate(0, 0)

        &::before
            content: ""
            width: 100%
            max-height: 300px
            height: 100%
            background-image: url(../img/home/training-shadow.png)
            background-size: contain
            background-repeat: no-repeat
            background-position: center
            position: absolute
            top: 40%
            right: -9%
            +d(991)
                display: none

    &-box
        flex: 0 0 33.333%
        margin-top: 90px
        margin-bottom: 250px
        position: relative
        +d(991)
            margin-top: 0
            margin-bottom: 40px
        +d(767)
            flex: 0 0 50%
        +d(575)
            flex: 0 0 100%

        &__content
            max-width: 270px
            background-color: $white
            margin-left: 50px
            +d(575)
                margin-left: 30px
                max-width: 100%

            h5, & .title-5
                margin-bottom: 10px
                position: relative

                +d(991)
                    &::before
                        content: ""
                        width: 10px
                        height: 10px
                        background-color: $primary
                        border-radius: 50%
                        position: absolute
                        top: 10px
                        left: -30px

.slider
    &__btn
        border: none
        background-color: transparent
        width: 38px
        height: 38px
        border-radius: 50%
        padding: 0
        +d(991)
            &:not(:last-child)
                margin-right: 30px

        &:hover
            .arrow-icon
                stroke: $white
                fill: $primary

.testimonials
    &__wrapper
        background-color: $grey
        border-radius: 10px
        padding: 72px 120px 55px

        .col-lg-6
            padding: 0 15px

        +d(991)
            padding: 40px
        +d(767)
            padding: 40px 0
            background-color: transparent
            position: relative

            &::before
                content: ""
                width: 100vw
                height: 100%
                background-color: $grey
                top: 0
                left: -30px
                position: absolute

    &__title
        margin-top: 8px

    &__social
        display: flex
        flex-direction: column
        align-items: flex-start
        margin-bottom: 110px
        +d(991)
            margin-bottom: 30px
            flex-direction: row
        +d(360)
            flex-direction: column

        .btn
            margin-bottom: 22px

    &__guarantee
        max-width: 300px
        +d(991)
            margin-bottom: 30px

        h5, & .title-5
            margin-bottom: 15px

    &__play
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

    &-slider
        max-width: 480px
        margin-left: -30px
        +d(991)
            max-width: 100%
            margin-left: 0

        &__wrap
            position: relative

        &__nav
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            z-index: 1
            width: calc(100% + 150px)
            display: flex
            justify-content: space-between

            +d(991)
                position: static
                transform: translate(0, 0)
                width: auto
                justify-content: flex-start

    &-item
        box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.03)
        background-color: $white
        border-radius: 10px
        padding: 30px 20px 15px 20px
        display: flex
        margin-bottom: 30px
        overflow: hidden
        +d(575)
            padding: 10px

        &__social
            flex: 0 0 31px
            margin-right: 16px

        &__wrapper
            position: relative

        &__header
            margin-bottom: 10px

        &__row
            display: flex
            align-items: center

        &__details
            display: flex
            align-items: center
            color: $greyDark
            font-size: 12px
            line-height: 22px

            &:not(:last-child)
                margin-right: 10px

            img, picture
                width: 11px
                height: 11px
                margin-right: 6px

        &_video
            position: relative
            min-height: 270px
            background-size: cover
            background-repeat: no-repeat
            background-position: center

.started
    padding: 180px 0
    +d(991)
        padding: 100px 0
    +d(575)
        padding: 50px 0

    &__title
        max-width: 340px
        +d(991)
            max-width: 100%

    &__content
        border-radius: 10px
        margin-right: 0
        margin-left: auto
        max-width: 600px
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        grid-template-rows: 1fr 1fr
        column-gap: 0
        row-gap: 0
        +d(991)
            max-width: 100%
        +d(768)
            grid-template-columns: 1fr 1fr

    &-box
        border: 1px solid $greyLight
        max-width: 200px
        width: 100%
        min-height: 250px
        transition: box-shadow .4s ease
        position: relative
        +d(991)
            max-width: 100%

        &:first-child
            border-top-left-radius: 10px
            +d(768)
                border-radius: 0

        &:last-child
            border-bottom-right-radius: 10px
            +d(768)
                border-radius: 0

        &:nth-child(3n)
            border-top-right-radius: 10px
            +d(768)
                border-radius: 0

        &:nth-child(4n)
            border-bottom-left-radius: 10px
            +d(768)
                border-radius: 0

        &::before
            content: ""
            width: calc(100% - 2px)
            width: 100%
            height: 100%
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            background-color: $white
            z-index: 10
            border-radius: 10px
            box-shadow: 0 0 0 rgba(242, 71, 58, 0.31)
            transition: background-color .4s ease, width .4s ease, height .4s ease

        &__content
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            width: 100%
            z-index: 11
            display: flex
            align-items: center
            justify-content: center
            flex-direction: column

        h6, .title-6
            transition: color .4s ease
            text-align: center
            padding: 0 35px

        &:hover
            z-index: 11

            &::before
                height: 270px
                background-color: $primary
                width: calc(100% + 2px)
                box-shadow: 0px 7px 19px rgba(242, 71, 58, 0.31)

            .svg-icon
                fill: $white

            h6, .title-6
                color: $white

        &__img
            position: relative
            margin-bottom: 40px

            &::before
                content: ""
                background-image: url(../img/svg/ellipse.svg)
                background-size: contain
                background-repeat: no-repeat
                background-position: top left
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                height: 100%
                width: 80px
                z-index: -1
                opacity: .3

.cta
    background-color: $grey
    padding-top: 170px
    background-size: contain
    background-position: bottom center
    background-repeat: no-repeat
    padding-bottom: 28.5%

    &_wh
        background-color: $white

    &_inner
        max-width: 520px
        margin: 0 auto

        p
            margin: 0 auto 30px
            max-width: 520px

    +d(768)
        background-size: 200%
        padding-top: 100px
        padding-bottom: 50%

    &__title
        text-align: center

        sup
            font-size: 16px
            top: -1.5em

        br
            +d(991)
                display: none

        h2, .title-2
            margin-bottom: 40px

        p
            span
                color: $primary

    &__img
        margin-top: -40px

    &__description
        color: $greyDark
        margin-bottom: 30px

        strong
            color: $black

    .btn
        min-width: 200px
