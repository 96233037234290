// project colors
$white: #ffffff
$black: #202020
$blackLight: #2F2F2F
$textColor: $black
$primary: #F2473A
$grey: #f2f2f2
$greyDark: #A0A0A0
$greyLight: #E0E0E0

// timing
$hover-duration: .5s
$duration: .8s
$timing: cubic-bezier(.17, .67, .55, 1.05)

$titleFont: "Orpheus Pro", 'Playfair Display', 'Times New Roman', Times, serif

$textFont: "Montserrat", Arial, Helvetica, sans-serif
