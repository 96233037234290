@import "../base/variables"
@import "../base/mixins"

.ucat
    &-page
        overflow: initial
        +d(991)
            overflow: hidden

    &_bg
        min-height: 575px

        .ucat-hero__img
            top: 0

            img, picture
                height: 100%

        &::before
            content: ''
            width: 100%
            height: 100%
            position: absolute
            top: 0
            left: 0
            bottom: 0
            right: 0
            background: linear-gradient(90deg, rgba(242, 242, 242, 1) 0%, rgba(242, 242, 242, 1) 60%, rgba(252, 176, 69, 0) 75%)
            z-index: 1

            @include d(991)
                background: #f2f2f2
                z-index: -1

    &-hero
        background-color: $grey
        padding: 50px 0 70px
        $root: &
        +d(991)
            padding: 35px 0 0 0

        &__img
            position: absolute
            right: 0
            bottom: 0
            max-width: 50%
            overflow: hidden

            img, picture
                object-fit: cover
                height: 100%

            +d(991)
                position: static
                max-width: 100%

        &__content
            position: relative
            z-index: 1

            .title-1, h2
                margin-bottom: 50px

                +d(991)
                    margin-bottom: 30px

                +d(767)
                    margin-bottom: 20px

            p
                max-width: 480px
                +d(991)
                    max-width: 100%

                &:not(:last-of-type)
                    margin-bottom: 20px

                &:last-of-type
                    margin-bottom: 40px
                    +d(991)
                        margin-bottom: 20px

        &_wide-text
            overflow: hidden

            #{$root}__content

                p
                    max-width: 100%

            ul
                margin-bottom: 50px

                +m(992)
                    column-count: 2

                li
                    position: relative
                    padding-left: 20px
                    margin-bottom: 10px

                    &::before
                        content: ""
                        width: 8px
                        height: 8px
                        background-color: $primary
                        border-radius: 50%
                        left: 0
                        top: 10px
                        position: absolute

        &__btn-holder
            margin-bottom: 30px

            p
                font-size: 14px
                color: $greyDark

                strong
                    color: $black

            +m(992)
                display: flex
                align-items: flex-start

                .btn
                    margin-right: 34px

    .title-1, h2
        margin-bottom: 50px
        +d(991)
            margin-bottom: 30px
        +d(767)
            margin-bottom: 20px

    p
        max-width: 480px
        +d(991)
            max-width: 100%

        &:not(:last-of-type)
            margin-bottom: 20px

        &:last-of-type
            margin-bottom: 40px
            +d(991)
                margin-bottom: 20px

    &-statistic
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        max-width: 480px

        &__box
            max-width: 50%
            flex: 0 0 50%
            margin-bottom: 20px

            i
                font-style: normal
                font-family: $titleFont
                font-size: 24px
                +d(1280)
                    font-size: 22px
                +d(575)
                    font-size: 20px

            span
                font-size: 24px
                display: inline-block
                line-height: 1.375em
                margin-bottom: 15px
                font-family: $titleFont
                +d(1280)
                    font-size: 22px
                +d(575)
                    font-size: 20px

            p
                color: $greyDark

.chance
    padding: 110px 0 90px 0

    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 80px 0
    +d(767)
        padding: 60px 0

    &__title
        text-align: center

        p
            max-width: 740px
            margin: 0 auto

    &-items
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        margin: 80px -80px 0

        &_hidden-text-mob

            +d(991)
                p
                    display: none

        +d(991)
            margin: 40px 0

    &-box
        max-width: 50%
        display: inline-flex

        &:not(:last-child)
            margin-bottom: 35px

        +d(767)
            max-width: 100%
            flex: 0 0 100%

            .online-learning-page &
                max-width: 40%
                flex: 0 0 40%

                &:nth-last-child(2)
                    margin-bottom: 0

        +d(991)
            flex-direction: column

        &__img
            margin-right: 40px
            position: relative
            width: 80px

            &::before
                content: ""
                width: 100%
                height: 100%
                position: absolute
                top: 0
                left: 0
                background-image: url(../img/svg/ellipse.svg)
                background-size: contain
                background-repeat: no-repeat
                background-position: top center

            +d(991)
                margin-right: 0
                margin-bottom: 20px

                .online-learning-page &
                    margin: 0 0 35px 20px

        &__content
            max-width: 420px

            +d(767)
                max-width: 100%

                .online-learning-page &
                    font-size: 17px

            .title-5, h5
                margin-bottom: 18px

.tool
    padding: 110px 0
    background-color: $grey

    +d(1199)
        padding: 100px 0

    +d(991)
        padding: 80px 0

        .online-learning-page &
            padding: 56px 0

            .title-2, h2
                font-size: 38px

    +d(767)
        padding: 60px 0

    &__title
        +d(991)
            text-align: center

    &__content
        max-width: 540px
        margin: 30px 0 0 auto

        +d(991)
            max-width: 100%
            margin: 0

    &-wrapper
        margin-top: 80px
        display: flex
        justify-content: space-between

        +d(991)
            margin-top: 20px
            flex-direction: column

    &-tabs

        +d(991)
            margin-bottom: 35px

    &-tab:not(.tool-tab_link)
        width: 255px
        height: 37px
        line-height: 37px
        text-align: center
        border: 1px solid $greyDark
        border-radius: 10px
        font-size: 14px
        font-weight: 600
        position: relative
        transition: all $hover-duration $timing

        +d(991)
            margin: 0 auto

        &:not(:last-child)
            margin-bottom: 17px

            +d(767)
                margin-bottom: 8px

        &.active
            color: $white
            background-color: $primary
            border-color: $primary

            &::before
                opacity: 1

    &-wrapper_links
        position: relative

        +m(992)
            &::after
                z-index: 10
                content: ''
                position: absolute
                pointer-events: none
                bottom: 0
                left: 0
                height: 90px
                width: 406px
                background: rgb(242, 242, 242)
                background: linear-gradient(0deg, rgba(242, 242, 242, 1) 0%, rgba(242, 242, 242, 0.5578606442577031) 100%)

    &-tabs_links
        position: relative
        max-height: 523px
        min-width: 380px
        overflow-y: auto
        cursor: all-scroll

        &::-webkit-scrollbar
            display: none
            -ms-overflow-style: none
            scrollbar-width: none

        +d(991)
            overflow: initial
            display: block
            max-height: initial
            min-width: initial
        +m(992)
            padding-bottom: 90px

    &-tab_link
        color: $black
        position: relative
        padding: 0 0 35px 36px
        font-weight: 600
        font-size: 18px
        line-height: 1.5
        cursor: pointer
        user-select: none
        +d(991)
            font-size: 16px
            padding: 0 0 12px 20px

        span
            transition: color .3s ease-in-out

        &:hover
            span
                color: $primary

        &.active
            color: $primary

        &:before
            content: ""
            width: 13px
            height: 13px
            background-color: #f2473a
            border-radius: 50%
            position: absolute
            top: 6px
            left: 0
            z-index: 10
            +d(991)
                top: 9px
                width: 7px
                height: 7px

        &::after
            content: ""
            width: 1px
            background-color: $black
            position: absolute
            top: -3px
            left: 6px
            height: calc(100% + 8px)
            z-index: 4
            +d(991)
                display: none

        &:first-child
            &::after
                +m(992)
                    top: 8px
                    height: calc(100% - 8px)

        &:last-child
            &::after
                display: none

    &-content
        max-width: 870px
        margin-left: 130px

        &_links
            img
                box-shadow: 0 15px 40px rgba(0, 0, 0, 0.25)

        +d(991)
            margin: 0 auto
        +d(767)
            margin: 0 -15px
            &_links
                margin: 0 auto
        +d(575)
            margin: 0 -30px
            &_links
                margin: 0 auto

    &-item
        display: none

        &:first-child
            display: block

.included
    font-size: 14px
    font-weight: 600
    text-transform: uppercase

    span
        color: $primary
        font-size: 20px
        +d(991)
            display: block

.included_black
    color: $greyDark

    span
        color: $black

.workshop
    padding: 80px 0 100px 0
    background-color: $black
    color: $white

    +d(1199)
        padding: 60px 0

    +d(991)
        padding: 60px 0 0 0

    .title-2, h2
        color: $white

    &__title
        text-align: center
        margin-bottom: 80px

        +d(1199)
            margin-bottom: 60px

        +d(991)
            margin-bottom: 40px

    &__content

        ul
            padding: 40px 0
            border-top: 1px solid $grey
            column-count: 2

            +d(991)
                column-count: 1

        .btn
            margin-bottom: 40px

    &__img
        max-width: 590px
        position: relative
        right: -60px

        +d(1199)
            right: 0

        +d(991)
            max-width: calc(100% + 30px)
            margin: 0 -15px

        +d(575)
            margin: 0 -30px
            max-width: calc(100% + 60px)

.coaching
    padding: 160px 0
    background-color: $grey

    +d(1199)
        padding: 100px 0

    +d(991)
        padding: 80px 0 0 0

    +d(767)
        padding: 60px 0 0 0

    &__img
        position: relative
        left: -60px
        max-width: 820px

        +d(1199)
            left: 0

        +d(991)
            max-width: calc(100% + 30px)
            margin: 0 -15px

        +d(575)
            margin: 0 -30px
            max-width: calc(100% + 60px)

    &__content
        max-width: 540px
        margin-right: 0
        margin-left: auto

        +d(991)
            max-width: 100%
            margin: 0 0 40px 0

        .included
            margin-left: 60px

            +d(1199)
                margin-left: 20px

            span
                display: block

        p
            margin-bottom: 10px

        ul
            column-count: 2
            padding: 30px 0
            border-top: 1px solid $greyLight
            margin-top: 30px

            +d(991)
                column-count: 1

.classes
    padding: 100px 0
    +d(1199)
        padding: 80px 0
    +d(991)
        padding: 60px 0 0 0

    &__title
        text-align: center
        margin-bottom: 70px
        +d(1199)
            margin-bottom: 50px
        +d(991)
            margin-bottom: 30px

    &__img
        position: relative
        right: -60px
        max-width: 660px

        .order-lg-1 &
            right: initial
            left: -60px

        +d(1199)
            right: 0
            .order-lg-1 &
                left: initial

        +d(991)
            max-width: calc(100% + 30px)
            margin: 0 -15px

        +d(575)
            margin: 0 -30px
            max-width: calc(100% + 60px)

    &__content
        max-width: 540px
        margin-left: 0
        margin-right: auto

        +d(991)
            max-width: 100%
            margin: 0 0 40px 0

        .included
            margin-left: 60px

            +d(1199)
                margin-left: 20px

            span
                display: block

        p
            margin-bottom: 10px

        ul
            padding: 30px 0
            border-top: 1px solid $greyLight
            margin-top: 30px

.reviews
    background-color: $grey
    padding: 100px 0
    +d(1199)
        padding: 80px 0
    +d(767)
        padding: 60px 0

    &__title
        text-align: center

        p
            max-width: 690px
            margin: 0 auto

    &-slider
        max-width: 100%
        margin-left: 0

        &__wrap
            .testimonials-slider__nav
                +d(991)
                    margin: 40px auto 0 auto
                    justify-content: center
                +d(767)
                    margin: 0 auto

        .slick-list
            padding-top: 80px !important
            +d(767)
                padding-top: 0 !important

        .slick-slide
            opacity: .3
            transition: all $hover-duration $timing

        .slick-center
            width: 480px
            opacity: 1
            position: relative
            transform: scale(1.1)
            z-index: 1
            +d(767)
                transform: scale(1)

        .testimonials__col
            min-width: 480px
            +d(767)
                min-width: 100%

.price
    font-size: 46px
    font-weight: 900
    text-align: right
    line-height: 1.1em
    margin-bottom: 20px
    +d(1280)
        font-size: 44px
    +d(991)
        font-size: 40px
        margin-bottom: 10px
    +d(575)
        font-size: 38px

    sup
        font-size: 16px
        top: -1.2em

    &-row
        display: flex
        align-items: center
        justify-content: space-between
        margin-bottom: 25px
        +d(991)
            margin-bottom: 10px

        p
            font-size: 12px
            text-transform: uppercase
            color: $greyDark

        span
            color: $black
            font-weight: 900

    span
        display: block
        line-height: 18px
        font-size: 12px
        text-transform: uppercase
        color: $greyDark

.package
    padding: 100px 0
    +d(1199)
        padding: 80px 0
    +d(767)
        padding: 60px 0

    &__title
        text-align: center
        margin-bottom: 180px
        +d(1280)
            margin-bottom: 120px
        +d(1199)
            margin-bottom: 80px
        +d(991)
            margin-bottom: 60px

        p
            max-width: 780px
            margin: 0 auto

    &__subtitle
        text-align: center
        margin-bottom: 60px

    &-row
        display: flex
        align-items: center
        justify-content: space-between
        margin-bottom: 140px
        +d(1199)
            margin-bottom: 100px
        +d(991)
            margin-bottom: 60px
        +d(767)
            flex-direction: column

    &-card
        max-width: 380px
        width: 100%
        border: 1px solid $grey
        border-radius: 10px
        padding: 25px 25px 35px 50px
        position: relative
        overflow: hidden

        +d(991)
            max-width: 340px
            padding: 35px 20px 20px 20px

        +d(767)
            margin-bottom: 30px

        &.most-popular
            padding: 35px 25px 35px 50px
            background-color: $grey
            +d(991)
                padding: 35px 20px 20px 20px

            &::before
                content: "\2605     Most Popular     \2605"
                position: absolute
                top: 0
                left: 0
                width: 100%
                background-color: $primary
                color: $white
                text-align: center
                font-size: 12px
                line-height: 30px
                height: 30px

        &__header
            .title-3, h3
                font-family: $textFont
                font-weight: 600
                margin-bottom: 20px

        &__body
            max-width: 285px

            .btn
                max-width: 277px
                margin: 0 auto
                display: block
                font-size: 14px

            ul
                padding: 20px 0 30px 0
                border-top: 1px solid $greyDark

                li
                    position: relative
                    padding-left: 20px
                    font-size: 12px
                    color: $greyDark

                    &::before
                        content: ""
                        width: 8px
                        height: 8px
                        background-color: $primary
                        border-radius: 50%
                        position: absolute
                        left: 0
                        top: 6px

    &-table
        +d(767)
            margin: 0 -15px
        +d(575)
            margin: 0 -30px

        &__header
            background-color: $grey
            width: 100%
            margin-bottom: 30px
            position: sticky
            top: 95px
            +d(767)
                margin-bottom: 0

            tr
                td
                    font-size: 18px
                    text-align: center
                    font-weight: 500
                    border: 1px solid $white
                    position: relative
                    width: 185px
                    +d(767)
                        font-size: 11px

                    .btn
                        max-width: 100px
                        padding: 11px 13px
                        position: absolute
                        bottom: -50%
                        left: 50%
                        transform: translate(-50%, -50%)
                        +d(767)
                            font-size: 8px
                            padding: 4px 6px
                            bottom: -30%
                            border-radius: 6px

                    p
                        color: $primary
                        font-size: 14px
                        margin-bottom: 12px

                        span
                            color: $greyDark
                            +d(767)
                                display: none

                    &:first-child
                        width: 580px
                        text-align: left
                        font-size: 16px
                        padding: 40px 0 40px 60px
                        +d(767)
                            padding: 24px 0 24px 30px
                            font-size: 12px

.table
    width: 100%
    font-size: 18px
    color: $black
    background-color: #FCFCFC
    +d(767)
        font-size: 12px

    tr
        height: 82px
        +d(767)
            height: 62px

        &:nth-child(odd)
            background-color: #fff

    th
        background-color: #fff
        color: $primary
        text-transform: uppercase
        font-size: 16px
        font-weight: 700
        padding: 20px 55px
        width: 580px
        +d(767)
            padding: 15px 7px
            font-size: 12px

    th, td
        border-top: none
        border-bottom: 1px solid $grey
        padding: 20px 55px
        vertical-align: middle
        +d(767)
            padding: 15px 7px

        p
            text-align: left
            font-weight: 600

    td
        border: 1px solid $grey

        &:first-child
            text-align: left
            font-weight: 500

        &:not(:first-child)
            width: 185px

        &:nth-child(3)
            background-color: rgba($grey, .5)

        picture, img
            width: 18px
            margin: 0 auto
            +d(767)
                width: 12px

        .table-disable
            width: 12px

.package-table
    &__online
        td
            &:first-child
                border-left: 4px solid $primary

    &__workshop
        td
            &:first-child
                border-left: 4px solid $black


.pricing
    margin-bottom: 120px
    +d(1199)
        margin-bottom: 60px

    &__content
        max-width: 435px
        +d(991)
            max-width: 100%
            text-align: center

        p
            &:not(:last-of-type)
                margin-bottom: 10px

            &:last-of-type
                margin-bottom: 35px

        picture, img
            +d(991)
                width: calc(100% + 30px)
                margin: 0 -15px 30px -15px
            +d(575)
                width: calc(100% + 60px)
                margin: 0 -30px

    &-accordion
        .card
            .btn
                white-space: normal
                padding: .975rem 4.5rem
                +d(767)
                    padding: 20px

                &::before
                    left: -5px

                &:after
                    left: 6px
                    height: 11px

        .card-body
            padding-top: 10px

            +d(767)
                padding-left: 40px

            &::before
                +d(767)
                    left: 0

        .card-header
            +d(767)
                padding: 0

.platform
    padding: 140px 0
    background-color: $black
    color: $white
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 80px 0
    +d(767)
        padding: 60px 0

    &__img
        text-align: center
        max-width: 667px
        position: relative
        margin-bottom: 50px
        left: -60px

        +d(1199)
            left: 0

        .btn
            margin-top: 50px

        +d(991)
            margin: 0
            max-width: 100%
            img
                display: none

    &__content
        width: 540px

        +d(1199)
            width: 100%
        +d(991)
            margin-bottom: 0

        .title-3, h3
            color: $white

        ul
            column-count: 2
            +d(991)
                column-count: 1

.offer
    background-color: #f2f2f2
    padding: 100px 0
    margin-bottom: 80px

    &_sm
        padding-top: 140px
        @include d(991)
            padding-top: 80px

    &-card
        background-color: #fff
        border-radius: 10px
        padding: 50px
        border: 1px solid #F2F2F2
        box-shadow: 0 7px 19px rgba(0, 0, 0, 0.03)

        &:not(:last-child)
            margin-bottom: 30px

        @include d(991)
            padding: 50px 30px

        &__header
            display: flex
            align-items: center
            justify-content: space-between
            border-bottom: 1px solid $greyDark
            @include d(991)
                flex-direction: column
                align-items: flex-start
                .title-3, h3
                    margin-bottom: 20px
                .price
                    color: $primary

            span
                text-transform: uppercase
                color: $greyDark
                font-size: 12px
                margin-bottom: 10px

        &__content
            padding-top: 20px
            display: flex
            align-items: center
            justify-content: space-between
            @include d(991)
                flex-wrap: wrap

            p
                max-width: 730px
                @include d(991)
                    margin-bottom: 20px

.ucat-table
    position: static

.online-learning-page
    +d(991)
        .cta
            padding-top: 60px

            .title-2
                font-size: 44px
                line-height: 1.3

            .btn
                min-width: 267px

        .ucat-hero
            .title-2
                font-size: 50px
                line-height: 1.3

            p
                line-height: 1.65

            ul
                margin-bottom: 32px

                li
                    line-height: 1.65

            .btn
                min-width: 206px

        .chance
            padding: 30px 0
