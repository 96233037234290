// project main styles
@import "variables"
@import "mixins"

body
    // font-size: 16px
    font-size: 18px
    // font-weight: 500
    font-weight: 400
    font-family: $textFont
    position: relative
    color: $textColor
    -webkit-font-smoothing: antialiased
    -webkit-backface-visibility: hidden
    -moz-backface-visibility: hidden
    -ms-backface-visibility: hidden
    -ms-text-size-adjust: 100%
    -moz-text-size-adjust: 100%
    -webkit-text-size-adjust: 100%
    backface-visibility: hidden

.title-1,
.title-2,
.title-3,
.title-4,
.title-5,
.title-6
    margin: 0
    // font-weight: 600
    font-family: $titleFont
    transition: color .4s ease

.title-1
    font-size: 96px
    line-height: 1.104em
    color: $black
    margin: 20px 0 35px
    // font-weight: 900
    +d(1280)
        font-size: 90px
    +d(1024)
        font-size: 80px
    +d(991)
        font-size: 60px
    +d(575)
        font-size: 50px
        margin: 10px 0 20px
    +d(360)
        font-size: 47px

    span
        color: $primary

.title-2
    font-size: 64px
    line-height: 1.250em
    margin-bottom: 20px
    +d(1280)
        font-size: 60px
    +d(1024)
        font-size: 50px
    +d(575)
        font-size: 44px
    +d(360)
        font-size: 40px

    span
        color: $primary

.title-3
    font-size: 48px
    line-height: 1.25em
    margin-bottom: 40px
    +d(1280)
        font-size: 44px
    +d(991)
        font-size: 40px
    +d(575)
        font-size: 38px

    span
        color: $primary

.title-4
    font-size: 34px
    line-height: 1.176em
    margin-bottom: 20px

    span
        color: $primary

.title-5
    font-size: 24px
    line-height: 1.375em
    margin-bottom: 15px
    +d(1280)
        font-size: 22px
    +d(575)
        font-size: 20px

.title-6
    font-size: 18px
    line-height: 1.444em

//.title-1
//    @extend h1
//
//.title-2
//    @extend h2
//
//.title-3
//    @extend h3
//
//.title-4
//    @extend h4
//
//.title-5
//    @extend h5
//
//.title-6
//    @extend h6

.accent-subtitle
    font-size: 24px
    color: $primary
    font-family: $titleFont

a
    display: inline-block
    text-decoration: none
    color: inherit
    line-height: 1.6em
    margin: 0

b,
strong
    font-size: inherit

img,
picture,
source
    width: 100%
    display: block

label
    cursor: pointer

// hide custom arrows
select
    -webkit-appearance: none
    appearance: none
    outline: none

select::-ms-expand
    display: none

p,
li
    line-height: 1.750em
    margin: 0

ul,
ol
    // padding-left: 0
    margin: 0

    li
        list-style: none

blockquote,
figure
    margin: 0

button
    &:hover
        cursor: pointer

    &:focus
        outline: none

// hide date navigation in input
input[type="date"]
    display: block
    -webkit-appearance: textfield
    -moz-appearance: textfield

    &::-webkit-inner-spin-button
        display: none

    &::-webkit-calendar-picker-indicator
        opacity: 0
        position: relative
        right: -10px

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator
        opacity: 0
        -webkit-appearance: none

// hide arrows in input type number
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

input[type="number"]
    -moz-appearance: textfield

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration
    -webkit-appearance: none

// input[type="password"]
//   background-image: url(../img/svg/eye.svg)
//   background-size: 20px
//   background-repeat: no-repeat
//   background-position: 95% center

textarea,
input
    color: $textColor
    // width: 100%
    // border: none
    transition: border-color $timing $hover-duration, color $timing $hover-duration

    &:focus
        outline: none
        border-color: $black

    &::-webkit-input-placeholder
        font-family: inherit
        // font-size: 16px
        font-size: 18px
        color: $textColor

    &::-moz-placeholder
        font-family: inherit
        // font-size: 16px
        font-size: 18px
        color: $black

    &:-ms-input-placeholder
        font-family: inherit
        // font-size: 16px
        font-size: 18px
        color: $black

    &::placeholder
        font-family: inherit
        // font-size: 16px
        font-size: 18px
        color: $black

textarea
    resize: none
    outline: none
    line-height: 1.2em
    height: 100%

.main-wrapper
    position: relative
    overflow: hidden
    opacity: 1
    transition: opacity $duration $timing

.container
    max-width: 1230px
    +d(575)
        padding: 0 30px

.container-fluid
    max-width: 1920px
    margin: 0 auto

.main
    padding-top: 128px

    &_inner
        padding-top: 110px

.section
    position: relative

    &__content
        // max-width: 440px
        +d(991)
            margin-bottom: 40px

        p
            margin-bottom: 35px

        ul
            li
                position: relative
                padding-left: 20px
                margin-bottom: 10px

                &::before
                    content: ""
                    width: 8px
                    height: 8px
                    background-color: $primary
                    border-radius: 50%
                    left: 0
                    top: 10px
                    position: absolute

    &__img
        max-width: 650px
        +d(767)
            max-width: calc(100% + 30px)
            margin: 0 -30px

.section__title
    margin-bottom: 40px
    +d(991)
        margin-bottom: 20px

    h2, .title-2
        margin-bottom: 20px

    p
        margin: 20px 0

.subtitle
    text-transform: uppercase
    font-weight: 600
    font-size: 18px
    line-height: 1.444em
    letter-spacing: 0.03em
    +d(991)
        font-size: 16px

    span
        color: $primary

.svg-icon
    height: 44px
    width: 44px
    fill: $primary
    transition: fill .4s ease

    &_md
        width: 22px
        height: 22px
        margin-right: 10px

    &_sm
        width: 11px
        height: 11px
        margin-right: 16px

.arrow-icon
    width: 38px
    height: 38px
    fill: transparent
    stroke: $primary
    transition: stroke .4s ease, fill .4s ease

.play
    display: inline-flex
    align-items: center
    font-size: 14px
    font-weight: 600
    transition: color .4s ease

    &:hover
        color: $primary
        text-decoration: none

        .play-btn
            background-color: $primary

            &::after
                transform: scale(1.5)
                opacity: 0

            &::before
                border-left: 9px solid $white

    &_transparent
        color: $white

        &:hover
            color: $white

            .play-btn
                border-color: $white
                background-color: $white

                &::before
                    border-left: 9px solid $primary

                &::after
                    border-color: $white

        .play-btn
            border-color: $white

            &::before
                border-left: 9px solid $white

            &::after
                border-color: $white

.play-btn
    position: relative
    width: 49px
    height: 49px
    flex: 0 0 49px
    border: 1px solid $primary
    border-radius: 50%
    margin-right: 16px
    transition: background-color .4s ease

    &::after
        content: ""
        width: 100%
        height: 100%
        border: 1px solid $primary
        border-radius: 50%
        position: absolute
        top: 0
        left: 0
        margin: -1px
        transform: scale(1) translate(1px, 1px)
        transition: transform .8s ease, opacity .8s ease

    &::before
        content: ""
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        width: 0
        height: 0
        border-top: 6px solid transparent
        border-bottom: 6px solid transparent
        border-left: 9px solid $primary

.play_white
    background-color: $white
    border-radius: 50%

    .play-btn
        margin-right: 0
        border: none

        &::after
            display: none

.navbar
    padding: 30px 45px
    +d(1199)
        padding: 20px 0

    &-nav
        margin: 0 auto

    &-collapse
        background-color: $white
        z-index: 10
        position: relative
        +d(1199)
            &::before
                content: ""
                width: 150vw
                height: 100%
                background-color: $white
                position: absolute
                left: -20vw
                top: 0
                z-index: -1

    &-text
        +d(1199)
            position: relative
            width: 100%
            margin-top: 40px
            padding: 15px 0
            &::before
                content: ""
                width: 200%
                height: 100%
                background-color: $grey
                position: absolute
                top: 0
                left: -50vw
                z-index: -1

.navbar-toggler
    width: 25px
    height: 15px
    padding: 0
    position: relative
    border: none

    &.collapsed
        .navbar-toggler-icon
            width: 25px

            &:before
                top: -7px
                transform: rotate(0)

            &::after
                transform: rotate(0)
                bottom: -7px

    .navbar-toggler-icon
        opacity: 1
        background-color: $black
        width: 0
        height: 3px
        position: absolute
        top: 50%
        right: 0
        transition: width .4s ease

        &:before, &::after
            content: ""
            position: absolute
            width: 25px
            height: 3px
            background-color: $black
            right: 0
            transform: rotate(0)
            transition: all .4s ease

        &:before
            top: 0
            transform: rotate(45deg)

        &::after
            bottom: 0
            transform: rotate(-45deg)

.navbar-brand
    max-width: 136px

.link
    color: $primary
    //font-size: 14px
    font-weight: 600

    &:hover
        color: $primary

    &_arrow
        position: relative
        white-space: break-spaces
        padding-right: 50px

        &::before
            content: ""
            position: absolute
            top: 50%
            right: 0
            transform: translate(0, -25%)
            background-image: url(../img/arrow.svg)
            background-size: contain
            background-repeat: no-repeat
            background-position: top center
            width: 36px
            height: 10px
            transition: left .4s ease

        &:hover
            &::before
                left: 107%

    &_arrow-reverce
        position: relative
        white-space: break-spaces
        padding-left: 50px

        &::before
            content: ""
            position: absolute
            top: 50%
            left: 0
            transform: translate(0, -50%) scale(-1)
            background-image: url(../img/arrow.svg)
            background-size: contain
            background-repeat: no-repeat
            background-position: top center
            width: 36px
            height: 10px
            transition: left .4s ease

        &:hover
            &::before
                right: 107%

.btn
    font-weight: 500
    padding: .775rem 3.5rem
    white-space: inherit
    +d(991)
        font-size: 16px
        max-width: 100%
        text-align: center
        padding: .775rem 3rem

    &-primary
        background-color: $primary

        &:hover
            background-color: $black
            border-color: $black

    &-outline-primary
        color: $black

    &-sm
        padding: .775rem 2.8rem
        border-radius: 0.625rem

    &-social
        display: inline-flex
        align-items: center
        font-size: 16px
        color: $black
        text-decoration: underline
        padding: 0
        +d(991)
            &:not(:last-child)
                margin-right: 30px

        img, picture
            flex: 0 0 20px
            max-width: 20px
            margin-right: 10px

.nav-item
    margin-right: 22px
    +d(1400)
        margin-right: 15px
    +d(1375)
        margin-right: 5px
    +d(1280)
        margin-right: 0

.btn-row
    display: flex
    align-items: center

    .btn:not(:last-child)
        margin-right: 25px
        +d(360)
            margin-right: 10px

.nav-link
    font-weight: 600
    +d(991)
        font-size: 22px
        line-height: 26px
    +d(767)
        font-size: 18px
        //padding: 5px 0

.hide-on-mobile
    +d(991)
        display: none

.show-on-mobile
    display: none
    +d(991)
        display: block

.tooltip-inner
    background-color: $white
    padding: 20px
    border-radius: 10px
    color: $black
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1)
    border: 1px solid $grey
    min-width: 340px

.tooltip
    z-index: 10

    &::before
        content: ""
        width: 400vw
        height: 400vh
        background-color: rgba($black, .6)
        opacity: 0
        position: fixed
        top: -100vw
        left: -100vw
        z-index: -1
        transition: opacity .4s ease
        pointer-events: none

.tooltip.show
    opacity: 1

    &::before
        opacity: 1

.form-group
    margin-bottom: 30px
    position: relative

    label:not(.form-check-label)
        margin-bottom: 0
        background-color: $white
        position: absolute
        top: -12px
        left: 30px
        padding: 0 10px
        color: $greyDark
        transition: color .4s ease
        font-size: 14px
        z-index: 1

.form-control
    height: 50px
    border-color: $greyDark
    border-radius: 10px
    outline: none
    color: $black
    font-weight: 500

    &:focus
        border-color: $black
        box-shadow: none

        & + label
            color: $black

    &:autofill
        background-color: $white !important

    &.is-invalid
        border-color: $primary
        color: $primary

        & + label
            color: $primary

.form-control.is-invalid, .was-validated .form-control:invalid
    background-image: none

.btn-search
    position: absolute
    top: 50%
    right: 10px
    transform: translate(-50%, -50%)
    padding: 0
    border: none
    background-color: transparent
    background-image: url(../img/svg/search.svg)
    cursor: pointer
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    width: 22px
    height: 22px

.contact-form
    .title-6, h6
        margin-bottom: 40px
        +d(991)
            display: none

    p
        max-width: 450px
        margin-bottom: 30px
        color: $greyDark

.form-row
    margin-bottom: 0
    margin-right: -8px
    margin-left: -8px
    justify-content: space-between


.form-check
    margin-bottom: 0
    +d(575)
        margin-bottom: 20px

.form-control.is-invalid, .was-validated .form-control:invalid
    outline: none

    &:focus
        background-color: $white
        box-shadow: none


.custom-accordion
    .card
        border: none

        &:nth-child(even)
            border-bottom: none
            font-weight: 600
            padding-left: 0

            & .card-header
                background-color: #F8F8F8

        .btn
            color: $black
            text-decoration: none
            position: relative
            font-weight: 600

            &::before, &::after
                content: ""
                background-color: $primary
                position: absolute
                top: 50%
                left: 20px
                transition: all .4s ease
                transform: translate(50%, -50%) rotate(45deg)

            &::before
                width: 12px
                height: 1px

            &::after
                width: 1px
                height: 12px
                left: 31px

            &.collapsed
                &::before, &::after
                    background-color: $greyDark
                    transform: translate(50%, -50%) rotate(0deg)

        &-header
            border-bottom: none
            background-color: transparent

        &-body
            padding: 0 0 0 100px
            margin: 15px 0
            position: relative
            max-width: 670px

            &:before
                content: ""
                width: 2px
                height: 100%
                background-color: $primary
                position: absolute
                top: 0
                left: 80px

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before
    border-top-color: $white

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before
    border-top-color: $white

.hide-on-mobile
    +d(991)
        display: none

.hide-on-desctop
    +m(992)
        display: none

.breadcrumb
    background-color: transparent
    +d(1199)
        display: none

.scroll
    overflow: auto
    padding: 0 0 14px 0
    margin: 0 5px 5px 0

    &::-webkit-scrollbar
        height: 14px !important

    &::-webkit-scrollbar:horizontal
        height: 14px !important

    &::-webkit-scrollbar-track
        border-radius: 14px !important
        background-color: $white !important
        // width: 14px !important
        height: 14px !important

    &::-webkit-scrollbar-thumb
        background-color: $primary !important
        border-radius: 14px !important
        width: 14px !important
        // height: 14px !important
        border: 2px solid #eee !important

    &::-webkit-scrollbar-thumb:window-inactive
        background: $primary !important

    &::-webkit-scrollbar
        width: 14px
        height: 18px

    &::-webkit-scrollbar-thumb
        height: 6px
        border: 4px solid rgba(0, 0, 0, 0)
        background-clip: padding-box
        background-color: $white

    &::-webkit-scrollbar-button
        display: none
        width: 0
        height: 0

    &::-webkit-scrollbar-corner
        background-color: transparent

.pagination
    align-items: center
    justify-content: center
    +d(420)
        flex-wrap: wrap

    .page-item
        display: flex
        align-items: center

        .btn
            +d(420)
                padding: .775rem 1rem
                width: 100%

        &:first-child
            margin-right: 60px
            +d(575)
                margin-right: 10px
            +d(420)
                order: 2
                flex: 0 0 120px
                max-width: 120px
                margin-top: 20px

            .page-link
                background-color: $primary
                color: $white

        &:nth-child(2)
            +d(420)
                margin-left: 80px

        &:nth-child(4)
            +d(420)
                margin-right: 80px

        &:last-child
            margin-left: 60px
            +d(575)
                margin-left: 10px
            +d(420)
                order: 3
                flex: 0 0 120px
                max-width: 120px
                margin-top: 20px

            .page-link
                border: 1px solid $primary

    .page-link
        border: none
        color: $black
        font-weight: 600

.overlay
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgba($black, .4)
    opacity: 0
    pointer-events: none
    z-index: 1
    transition: opacity .4s ease

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit
    width: 43px
    +d(1024)
        width: 33px

.accordion-clean
    ol, ul
        padding-left: 20px

        li
            list-style: initial

    .card:nth-child(even), .card:nth-child(odd)
        font-weight: 400

    .card-body:before
        display: none


.form-row > .col, .form-row > [class*=col-]
    padding-left: 8px
    padding-right: 8px

.form-select
    margin-top: 30px
    margin-bottom: 50px
    display: block
    width: 100%
    padding: 0.375rem 2.25rem 0.375rem 0.75rem
    -moz-padding-start: calc(0.75rem - 3px)
    //font-size: 1rem
    font-weight: 400
    line-height: 1.5
    height: 50px
    border: 1px solid $black
    //color: $black
    background-color: #fff
    background-repeat: no-repeat
    background-image: url(../img/svg/arrow-select.svg)
    background-position: right 0.75rem center
    background-size: 16px 7px
    border-radius: 10px
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    font-size: 14px
    color: $greyDark

//&.disabled
//    pointer-events: none
//    border: 1px solid #ced4da
//    color: #ced4da

.university-content
    li
        list-style: initial
