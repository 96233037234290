@import "../base/variables"
@import "../base/mixins"

.compare
    &-page
        overflow: visible

        .title-1, h1
            font-size: 80px
            line-height: 1em
            +d(991)
                font-size: 60px
            +d(575)
                font-size: 50px
            +d(360)
                font-size: 47px

    &-hero
        padding: 20px 0 115px 0
        +d(1199)
            padding: 0 0 80px 0

        &__content
            margin-top: 33px
            +d(991)
                margin-top: 0

            .title-5, h5
                color: $primary
                margin-bottom: 20px

            p
                // font-weight: 500
                font-weight: 400

    &-tables
        padding: 125px 0 100px 0
        background-color: $grey
        +d(1199)
            padding: 80px 0
        +d(991)
            padding: 50px 0

        &__content
            margin: 12px 0 0 20px
            +d(991)
                margin: 0

            .svg-icon_sm
                margin: 0 5px

        &__box
            margin: 24px 55px 85px 50px
            max-width: 1200px
            +d(1199)
                margin: 30px 0

    &-table
        width: 100%
        border-collapse: separate
        border-spacing: 1px
        background-color: $white
        min-width: 1000px

        tr
            &:first-child
                th
                    color: $white
                    background-color: $black
                    font-weight: 400
                    padding: 22px 20px
                    +d(991)
                        padding: 12px 0

                    &:first-child
                        font-family: $titleFont
                        font-size: 30px
                        padding-left: 50px
                        line-height: 1.5em
                        width: 320px
                        +d(991)
                            font-size: 18px
                            padding-left: 20px

                    &:nth-child(2), &:nth-child(3)
                        text-align: center
                        padding: 10px

                    &:last-child
                        width: 430px

        tr
            td
                &:first-child
                    padding: 26px 42px
                    +d(991)
                        padding: 13px 20px

                &:last-child
                    padding: 26px 20px
                    +d(991)
                        padding: 13px 10px

                &:nth-child(2), &:nth-child(3)
                    text-align: center

                .svg-icon_sm
                    width: 18px
                    height: 18px

.unsure
    padding: 95px 0 160px 0
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 50px 0 0 0

    &__img
        max-width: 520px
        +d(991)
            margin: 0 auto 50px auto
        +d(767)
            max-width: calc(100% + 0px)
            margin: 0 -30px

    &__content
        max-width: 500px
        @include d(991)
            max-width: 100%
            margin-bottom: 50px

        .title-2, h2
            margin-bottom: 30px

        p
            margin-bottom: 16px

            &:last-of-type
                margin-bottom: 30px

.compare__row
    overflow: auto

.compare-spy
    padding-left: 40px
    position: sticky
    top: 100px
    margin: 20px 0 0 -40px
    +d(991)
        position: relative
        top: 0
        display: none

    &::before
        content: ""
        width: 1px
        height: 90%
        background-color: $black
        position: absolute
        top: 50%
        left: 0
        transform: translate(-50%, -50%)
        +d(991)
            width: 90%
            height: 1px
            top: 0
            left: 50%

    .list-group-item
        border: none
        background-color: transparent
        color: $greyDark
        padding: 6px
        position: relative

        &::before
            content: ""
            width: 11px
            height: 11px
            background-color: $primary
            border-radius: 50%
            position: absolute
            top: 50%
            left: -45px
            transform: translate(0, -50%)
            opacity: 0
            transition: opacity .4s ease

        &.active
            color: $black
            background-color: transparent

            &::before
                opacity: 1
