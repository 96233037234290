@import "../base/variables"
@import "../base/mixins"

.know
    &-wrapper
        padding: 110px 60px
        position: relative
        +d(1199)
            padding: 60px 0

        .list-group
            border: none
            position: relative
            top: 0
            left: 0
            z-index: 9
            +d(991)
                display: none

            &::before
                content: ""
                width: 100vw
                background-color: $white
                position: absolute
                top: -10vh
                right: 0
                height: 110vh

            .list-group-item
                border: none
                border-radius: 0
                color: $greyDark

                &.active
                    color: $black
                    background-color: $white

                &:hover
                    background-color: $white
                    color: $black

    &-spy
        position: relative

.how
    +d(991)
        padding-top: 40px

.important
    &__img
        max-width: 290px
        margin: 30px auto 110px auto

    &-row
        display: flex
        align-items: flex-start
        justify-content: space-between
        +d(991)
            margin-bottom: 40px

    &-icon
        width: 80px
        height: 50px
        position: relative

        &::before
            content: ""
            width: 100%
            height: 100%
            background-image: url(../img/svg/ellipse.svg)
            background-size: contain
            background-repeat: no-repeat
            background-position: center
            position: absolute
            top: 0
            left: 50%
            transform: translate(-50%, 0)
            z-index: -1

        &::after
            width: 24px
            height: 24px
            background-image: url(../img/svg/plus.svg)
            background-size: contain
            background-repeat: no-repeat
            background-position: center
            position: absolute
            right: 0
            top: 50%


        img
            height: 50px
            width: auto
            margin: 0 auto

.hard
    padding-top: 120px

    &::before
        content: ""
        width: 200vw
        min-height: 985px
        position: absolute
        top: 0
        left: -50vw
        background-color: $grey

.structure
    padding: 100px 0

    &::before
        content: ""
        width: 200vw
        min-height: 100%
        position: absolute
        top: 0
        left: -50vw
        background-color: $grey

    &__title
        text-align: center

        p
            font-weight: bold
            font-size: 18px
            max-width: 460px
            margin: 0 auto 45px

    &__table

        table
            width: 100%
            border-collapse: collapse
            border-color: $white
            margin-bottom: 70px

            th
                height: 89px
                background-color: $black
                text-align: center
                color: $white
                font-weight: 600
                border: 1px solid $grey

            tr
                td
                    height: 90px
                    text-align: center
                    border: 2px solid #f2f2f2
                    background-color: #fcfcfc

                &:nth-child(odd)
                    td
                        background-color: $white

    &__footer
        text-align: center

        p
            margin-bottom: 30px


.sit
    padding: 150px 0
    +d(1199)
        padding: 100px 0
    +d(991)
        padding: 60px 0

    &__title
        text-align: center
        margin-bottom: 60px

    &__wrapper
        position: relative
    // &::before
    //   content: ""
    //   width: 200vw
    //   min-height: 100%
    //   position: absolute
    //   top: 0
    //   left: -50vw
    //   background-color: $grey
    //   z-index: -1
    &__row
        display: flex
        align-items: flex-start
        justify-content: flex-start
        flex-wrap: wrap

    &-box
        flex: 0 0 220px
        text-align: center
        margin-bottom: 60px
        padding: 0 15px 0
        +d(767)
            flex: 0 0 50%
            max-width: 50%

        &__img
            height: 100px
            margin-bottom: 10px

            img, picture
                width: auto
                margin: 0 auto

        p
            text-align: center
            font-size: 14px
            line-height: 20px

        strong
            display: block
            font-size: 16px

.when
    padding: 140px 0 60px 0
    color: $white
    +d(1199)
        padding: 60px 0
    +d(991)
        background-color: $black
        //padding-bottom: 0
        margin: 0 -15px
        padding: 60px 30px 0

    .title-3, h3
        color: $white

    &::before
        content: ""
        width: 200vw
        min-height: 800px
        position: absolute
        top: 0
        left: -50vw
        background-color: $black
        z-index: -1
        +d(991)
            display: none

.prepare
    padding: 90px 0
    +d(991)
        padding: 40px 0

    &__content
        +d(991)
            margin-bottom: 40px

        p
            margin-bottom: 10px

        .title-6, h6
            margin-top: 50px
            margin-bottom: 25px

        ul
            li
                position: relative
                padding-left: 15px
                margin-bottom: 18px

                p
                    margin-bottom: 0

                &::before
                    content: ""
                    width: 6px
                    height: 6px
                    background-color: $primary
                    border-radius: 50%
                    position: absolute
                    top: 10px
                    left: 0

    &__img
        max-width: 420px
        margin: 0 auto

.sesions
    padding: 70px 0

    .my-medentry__video
        margin: 40px 0
        +d(991)
            margin: 0
            margin-left: -30px
            margin-bottom: 30px
            width: calc(100% + 60px)

    &::before
        content: ""
        width: 200vw
        min-height: 800px
        position: absolute
        top: 0
        left: -50vw
        background-color: $grey
        z-index: -1

    &__video
        p
            font-weight: 600

.know-navigation
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 200px
    +d(1199)
        margin-bottom: 100px
    +d(991)
        margin-bottom: 60px

.online
    padding: 120px 0 160px
    color: $white
    +d(1199)
        padding: 60px 0

    &::before
        content: ""
        width: 200vw
        min-height: 100%
        position: absolute
        top: 0
        left: -50vw
        background-color: $black
        z-index: -1

    .title-6, h6, .title-4, h4
        color: $white
        +d(991)
            margin-bottom: 20px

    p
        margin-bottom: 30px

    &__img
        +d(991)
            margin-bottom: 40px

    &__content
        text-align: center
